import MediaCarousel from "./media-carousel";
import { Card, CardBody } from "@nextui-org/card";
import { InstagramIcon } from "./icons";
import { formatFollowerCount } from "~/utils/misc";
import { Result } from "~/stores/search";
import { prettyLocation } from "~/utils/location";
import posthog from "posthog-js";
import { HiSparkles } from "react-icons/hi2";
import { Link } from "@remix-run/react";
import { track } from "~/utils/track";

export default function Provider({
  slug,
  differentiator,
  name,
  images,
  q,
  instagram_followers,
  location_label,
}: Result & { q: string | null }) {
  if (!name || name.length === 0) {
    return null;
  }

  return (
    <Link
      to={`/p/${slug}?q=${q && encodeURIComponent(q)}`}
      onClick={() => {
        posthog.capture("result_opened", {
          query: q,
          name: name,
          differentiator: differentiator,
          instagram_followers: instagram_followers,
          images,
          location_label,
        });
        track("result_opened", {
          query: q,
          name: name,
          differentiator: differentiator,
          instagram_followers: instagram_followers,
          images,
          location_label,
        });
      }}
    >
      <Card className="flex-shrink-0 w-full h-full relative group overflow-visible">
        <div className="absolute top-2 left-2 z-10 "></div>
        <div className="scale-[1.02] bg-background shadow-lg rounded-2xl">
          {images.length > 0 ? (
            <MediaCarousel media={images} />
          ) : (
            <img
              loading="lazy"
              alt="placeholder"
              className="w-full h-full aspect-square object-cover rounded-2xl"
              src="/assets/blur.webp"
            />
          )}
        </div>
        <CardBody>
          <div className="pt-2">
            <div className="flex items-center justify-between">
              <h4 className="text-left font-semibold text-lg line-clamp-1">
                {name}
              </h4>

              {instagram_followers && instagram_followers > 0 && (
                <div className="flex items-center space-x-2">
                  <InstagramIcon width={20} height={20} />
                  <p className="text-sm font-bold">
                    {formatFollowerCount(instagram_followers)}
                  </p>
                </div>
              )}
            </div>
            {location_label && (
              <p className="text-default-500 text-sm">
                {prettyLocation(location_label)}
              </p>
            )}
            <div className="flex flex-wrap items-center gap-2 mt-2">
              {differentiator
                .split(" | ")
                .slice(1)
                .map((text) => (
                  <div
                    key={text}
                    className="bg-primary-50 bg-opacity-50 px-2 py-1 rounded-full flex items-center gap-1"
                  >
                    <HiSparkles className="w-3 h-3 text-primary" />
                    <p className="text-primary text-sm sm:text-xs">{text}</p>
                  </div>
                ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </Link>
  );
}
